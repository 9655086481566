<template>
    <div>
        <div class="tw-mt-3 tw-mb-6">
            <h1 class="section-divider-underline">{{ $t("employee.attachment") }}</h1>
            <div class="col-md-12">
                <div class="d-flex justify-content-between tw-mb-4">
                    <label class="btn btn-sm btn-flat-info btn-outline-info" for="employee">{{
                        $t('employee.uploadFile')
                    }}</label>
                    <input type="file" id="employee" ref="employee" style="display:none" multiple
                        @change="onUploadFileChange" />
                </div>
                <table style="width:80%">
                    <tr v-for="(file, index) in model.attach_file_list" :key="index">
                        <td style="width: 18%;"></td>
                        <td style="width:20%">
                            <FileView :resource="file" @click="onViewFile(index)" class="tw-py-1" />
                        </td>
                        <td style="width:25%" class="mt-2" :class="{ 'text-red': file.invalid_file }">
                            {{ file.original_file_name }}
                        </td>
                        <td class="text-center" style="width:5%">
                            <Poptip confirm :title="$t('areYouSure')" @on-ok="onRemoveFile(index, file)" :transfer="true"
                                :ok-text="$t('yes')" :cancel-text="$t('no')">
                                <a class="ml-2 text-danger" v-tooltip="$t('delete')" v-if="file.progressing == 100">
                                    <i class="fa fa-times" v-if="file._deleting"></i>
                                    <Icon type="ios-trash" size="20" v-else />
                                </a>
                            </Poptip>
                        </td>
                    </tr>
                </table>
            </div>
            <Modal v-model="showModalFileView" :zIndex="1020" :mask="true" :footer-hide="true" width="40%"
                :title="$t('employee.viewFile')">
                <div class="tw-rounded-md group tw-relative tw-text-center tw-justify-between tw-items-center">
                    <button @click.prevent="onMoveBack"
                        class=" hover:tw-bg-gray-400 tw-absolute tw-align-middle tw-inline-block tw-left-0 tw-ml-3 tw-bg-gray-300 tw-px-4 tw-py-2 tw-rounded-md tw-items-center tw-justify-items-center"
                        style="margin-top:280px;">
                        <i class="fas fa-chevron-left tw-text-gray-700"></i>
                    </button>
                    <button @click.prevent="onMoveNext"
                        class=" hover:tw-bg-gray-400 tw-absolute tw-right-0 tw-align-middle tw-inline-block tw-mr-3 tw-bg-gray-300 tw-px-4 tw-py-2 tw-rounded-md tw-items-center tw-justify-items-center"
                        style="margin-top:280px;">
                        <i class="fas fa-chevron-right tw-text-gray-700"></i>
                    </button>
                    <ViewModal :fileResource="currentViewFileResource" />
                </div>
            </Modal>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import httpClient from '@/http-client'
import { forEach, map, isEmpty } from 'lodash'
import FileView from "./file-view.vue";
import ViewModal from "./view-modal.vue";
import { mapActions, mapState } from "vuex";

export default {
    name: "attach-file",
    props: ["value", "validation"],
    components: {
        FileView,
        ViewModal
    },
    data() {
        return {
            model: {},
            currentViewIndex: 0,
            showModalFileView: false,
            errors: new Errors()
        };
    },
    computed: {
        ...mapState('humanResource/employee', ['edit_data']),
        isEdit() {
            return !isEmpty(this.edit_data) && this.edit_data !== undefined
        },
        currentViewFileResource() {
            if (this.model) {
                return this.model.attach_file_list[this.currentViewIndex]
            }
            return undefined
        }
    },
    created() {
        this.model = this.value;
        this.setEdit()
    },
    methods: {
        ...mapActions('humanResource/employee', ['removeFileUploaded']),
        onUploadFileChange(e) {
            let files = e.target.files
            this.model.attach_file_list = [
                ...this.model.attach_file_list,
                ...map(files, file => ({
                    picture_id: 0,
                    file_path: null,
                    original_file_name: file.name,
                    remarks: null,
                    size: parseInt(file.size / 1024),
                    size_text: parseInt(file.size / 1024) + 'KB',
                    type: file.type,
                    progressing: 0,
                    invalid_file: this.validate(file),
                    file: file,
                    uploaded: false,
                    storage_path: null
                }))
            ]
            forEach(
                this.model.attach_file_list.filter(file => file.uploaded == false),
                (f) => {
                    if (!this.validate(f.file)) {
                        let frm = new FormData()
                        frm.append('file', f.file)
                        frm.append('file_type', 1)
                        httpClient
                            .post('/api/employees/upload-files', frm, {
                                onUploadProgress: el => {
                                    if (el.lengthComputable) {
                                        f.progressing = Math.round((el.loaded * 100) / el.total)
                                    }
                                }
                            })
                            .then(response => {
                                f.uploaded = true
                                f.storage_path = response.data.file_path
                                f.file_path = response.data.file_path
                            })
                            .catch(error => {
                                this.$toasted.error(error.message)
                            })
                    }
                }
            )
        },
        validate(file) {
            let ALLOW_FILE = [
                'image/png',
                'image/jpeg',
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/vnd.ms-powerpoint',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/msword'
            ]

            if (file.size > 10000000 * 10 || !ALLOW_FILE.includes(file.type)) {
                return true
            }

            return false
        },
        onViewFile(index) {
            this.showModalFileView = true
            this.currentViewIndex = index
        },
        onRemoveFile(index, record) {
            if (record.id > 0) {
                this.removeFileUploaded({
                    id: record.id,
                    file_path: record.file_path
                })
            }
            let file_index = this.model.attach_file_list.indexOf(
                ...this.model.attach_file_list.filter(
                    f => f.file_path == record.file_path && f.id == record.id
                )
            )
            this.model.attach_file_list.splice(file_index, 1)
        },
        onMoveBack() {
            this.currentViewIndex =
                this.currentViewIndex - 1 > 0
                    ? this.currentViewIndex - 1
                    : this.model.attach_file_list.length - 1
        },
        onMoveNext() {
            this.currentViewIndex =
                this.currentViewIndex + 1 <= this.model.attach_file_list.length - 1
                    ? this.currentViewIndex + 1
                    : 0
        },
        setEdit() {
            if (this.isEdit) {
                this.model.attach_file_list = this.edit_data.file_lists
                    .map(el => ({
                        original_file_name: el.original_file_name,
                        size: 0,
                        size_text: 0,
                        type: el.mime_type,
                        progressing: 100,
                        invalid_file: false,
                        file: el.file_path,
                        file_path: el.file_path,
                        uploaded: true,
                        id: el.id,
                        storage_path: el.storage_path,
                        remarks: el.remarks
                    }))
            }
        }
    },
    watch: {
        validation: {
            handler() {
                this.errors = new Errors(this.validation.errors);
            },
            deep: true
        }
    }
};
</script>

<style lang="css" scoped>
.section-divider-underline {
    padding-bottom: 5px;
    margin: 0 0 8px 0;
    /* border-bottom: 1px solid #eee; */
    font-size: 16px;
    color: #929090;
    font-weight: 400;
    text-transform: uppercase;
}
</style>
