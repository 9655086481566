<template>
    <ts-panel>
        <ts-panel-wrapper>
            <div class="d-flex flex-column align-items-center">
                <ts-image-input
                    class="image-input"
                    :file="file"
                    @input="file => (model.photo = file)"
                    :width="175"
                    :height="225"
                    crop
                ></ts-image-input>
                <span class="mt-2"
                    ><i>{{ $t("employee.photo") }}</i></span
                >
                <div style="color:#cc4946;margin:4px 0 0">
                    {{ errors.first("photo") }}
                </div>
            </div>
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t("employee.cardNumber")
                }}</label>
                <input
                    type="text"
                    v-model="model.card_id"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('card_id') }"
                    readonly
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('card_id')"
                >
                    {{ errors.first("card_id") }}
                </div>
            </div>
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t("employee.nameKh")
                }}</label>
                <input
                    ref="employee_name_kh"
                    type="text"
                    v-model="model.employee_name_kh"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('employee_name_kh') }"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('employee_name_kh')"
                >
                    {{ errors.first("employee_name_kh") }}
                </div>
            </div>
            <div class="mb-3">
                <label class="form-col-label control-label required">{{
                    $t("employee.nameEn")
                }}</label>
                <input
                    type="text"
                    v-model="model.employee_name_en"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('employee_name_en') }"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('employee_name_en')"
                >
                    {{ errors.first("employee_name_en") }}
                </div>
            </div>
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t("employee.dob")
                }}</label>
                <DatePicker
                    type="date"
                    :value="model.date_of_birth"
                    style="width:100%"
                    placeholder="DD-MM-YYYY"
                    format="dd-MM-yyyy"
                    @on-change="onChangeDateOfBirth"
                ></DatePicker>
            </div>
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t("employee.nationality")
                }}</label>
                <select
                    v-model.number="model.nationality_id"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('nationality_id') }"
                >
                    <option :value="null">{{ $t("select") }}</option>
                    <option
                        v-for="(nationality, index) in nationalities"
                        :key="index"
                        :value="nationality.nationality_id"
                        >{{ nationality.nationality_name_en }}</option
                    >
                </select>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('nationality_id')"
                >
                    {{ errors.first("nationality_id") }}
                </div>
            </div>
            <div class="form-check form-check-inline">
                <input
                    class="form-check-input"
                    type="radio"
                    id="male"
                    v-model="model.sex"
                    value="Male"
                />
                <label class="form-check-label" for="male">Male</label>
            </div>
            <div class="form-check form-check-inline">
                <input
                    class="form-check-input"
                    type="radio"
                    id="female"
                    v-model="model.sex"
                    value="Female"
                />
                <label class="form-check-label" for="female">Female</label>
            </div>
            <div class="text-danger" v-if="errors.has('sex')">
                {{ errors.first("sex") }}
            </div>
            <div class="mt-3">
                <ts-checkbox v-model="model.is_employer" disabled readonly>{{
                $t('employee.isEmployerToDisplayInContract')
                }}</ts-checkbox>
            </div>
            <div class="mt-3">
                <ts-checkbox v-model="model.is_audit">{{
                $t('employee.isAudit')
                }}</ts-checkbox>
            </div>
            <div class="mt-3">
                <ts-checkbox v-model="model.is_sm">{{
                $t('employee.isSM')
                }}</ts-checkbox>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from "form-backend-validation";
import { isEmpty } from "lodash";
import { mapState } from "vuex";

export default {
    name: "profile",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            employeeLoading: false,
            file: require("../../../../assets/staff-default-man.png"),
            model: {}
        };
    },
    computed: {
        ...mapState("humanResource/employee", ["edit_data"]),
        isEdit() {
            return !isEmpty(this.edit_data);
        },
        nationalities() {
            return this.$store.state.humanResource.employee.nationalities;
        }
    },
    mounted() {
        this.$refs.employee_name_kh.focus();
    },
    created() {
        this.model = this.value;
    },
    methods: {
        onChangeDateOfBirth(date){
            this.model.date_of_birth = date
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.photo = this.edit_data.photo;
                this.file = this.model.photo;
            }
        }
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
